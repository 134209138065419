import React from "react";
import { Link } from "gatsby";

export const data_webdev = {
  what_we_do: {
    title: "Czy wiesz, do czego może przyczynić się dobra strona WWW?",
    text: [
      <>
        Zbuduje pozytywny obraz marki i tego co robisz na lata. Twoja strona
        internetowa to Twoja wizytówka. Pozwól, by wywarła jak najlepsze
        pierwsze wrażenie o Twoim biznesie.
      </>,
      <>
        Sprawnie poprowadzi Twoich klientów do celu. To właśnie na niej Twoi
        klienci znajdą wszystkie informacje, które pomogą w poznaniu marki,
        przyjrzeniu się działalności i nawiązaniu kontaktu.
      </>,
      <>
        Wzmocni wizerunek i wiarygodność firmy oraz pozwoli wyjść naprzeciw
        potrzebom odbiorców. Projektowane przez nas strony są w pełni
        responsywne, dzięki czemu korzystanie z nich jest możliwe w każdym
        momencie.
      </>,
      <>
        Osiągnie jak najwyższe pozycje w wynikach wyszukiwania dzięki
        optymalizacji SEO. Znalezienie Twojej marki w sieci będzie łatwiejsze,
        Twój biznes będzie się rozwijał, a ilość osób, które odwiedzają Twoją
        stronę, się zwiększy.
      </>,
    ],
  },
  our_process: {
    title: "Nasz proces projektowy",
    steps: [
      {
        title: "Planowanie",
        list: [
          "Opracowanie briefu",
          "Audyt obecnych zasobów",
          "Analiza konkurencyjnych rozwiązań",
          "Dobór technologii do założeń projektu",
        ],
      },
      {
        title: "Projektowanie graficzne",
        list: [
          "Koncepcja funkcjonalna",
          "Opracowanie Makiet",
          "Projektowanie Widoków",
          <Link to="/uslugi/web-design">
            Zobacz szczegółowy proces projektowania graficznego
          </Link>,
        ],
      },
      {
        title: "Wdrożenie",
        list: [
          "Dobór usługi hostingowej do zastosowanej technologii",
          "Utworzenie repozytorium plików",
          "Utworzenie struktury projektu",
          "Współpraca przy UX/UI",
          "Programowanie",
          "Współpraca przy treściach oraz SEO",
        ],
      },
      {
        title: "Obsługa",
        list: [
          "Aktualizacje systemu",
          "Wdrożenie udoskonaleń funkcjonalnych",
          "Tworzenie kopii bezpieczeństwa",
          "Monitorowanie działania systemu",
        ],
      },
    ],
  },
};
